.step__component {
    gap: 10px;
    border: 1px solid var(--background-3);
    background-color: var(--background-0);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    min-width: 280px;
    max-width: 600px;
}

.step__component h3 {
    padding: 10px 0px;
    color: var(--white-1);
    font-size: 2.5em;
}

.step__label{
    width: 100%;
    display: flex;
    border-top: 1px solid var(--background-3);
    padding: 5px 10px;
    gap: 12px;
}

.step__label label {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.step__label strong {
    font-size: 1.4em;
    font-weight: 300;
    color: var(--button-1);
}

.step__label input,
.step__label select,
.step__label textarea {
    width: 100%;
    padding: 6px;
    font-size: 1.3em;
    border: 1px solid var(--background-3);
    color: var(--white-1);
    background-color: var(--menu);
    resize: none;
}

.step__label option {
    color: var(--white-1);
}

.step__label input::placeholder,
.step__label select::placeholder,
.step__label textarea::placeholder {
    color: var(--white-1);
}

.step__label input:focus,
.step__label select:focus,
.step__label textarea:focus {
    outline: 1px solid var(--white-1);
}

.step__label textarea {
    font-size: 1.7em;
    height: 140px;
}

.step__label input:disabled {
    color: var(--main-1);
    border: 1px solid var(--background-3);
    background-color: var(--background-1);
}

.step__component button {
    background-color: var(--main-2);
    color: var(--background-1);
    font-size: 1.4em;
    margin: 10px 0px;
}

.step__component button:disabled {
    cursor: not-allowed;
    color: var(--white-1);
    background-color: var(--background-1);
}

.tags-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.tags-container .tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
    font-size: 1.5em;
    background-color: var(--button-1);
    gap: 5px;
    color: var(--background-1);
    border-radius: 20px;
    cursor: pointer;
    transition: all .2s ease;
}

.tags-container .tag:hover {
    background-color: var(--alert-fail-2);
    color: var(--white-1);
}

.tags-container .tag button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 0px;
    background-color: transparent;
}

@media only screen and (min-width: 800px) {
    .step__component {
        width: 500px;
    }
}
.chat {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100dvh;
    padding: 10px;
}

.chat__flex {
    flex: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.chat__flex fieldset {
    padding: 10px;
    border: 1px solid var(--background-3);
    border-radius: 8px;
    color: var(--white-1);
    font-size: 10pt;
}

.chat__flex .whatsapp {
    background-color: #25D366 ;
    color: var(--background-1);
    font-size: 15pt;
    border: 1px solid var(--background-3);
    border-radius: 8px;
    padding: 8px 15px;
    gap: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.chat__flex .whatsapp:hover {
    cursor: pointer;
}

.chat__container {
    flex: 2;
    display: flex;    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
}

.chat__top {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.chat__top h2 {
    font-size: 20pt;
    color: var(--white-1);
}
.chat__mid {
    display: flex;    
    flex-direction: column;
}
.chat__mid fieldset {
    height: 100%;
    padding: 10px;
    border: 1px solid var(--background-3);
    border-radius: 8px;
    display: flex;
    flex-direction: column-reverse;
}

.chat__bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
}

.chat__bottom > .inp-msg {
    border: 1px solid var(--background-3);
    border-radius: 8px;
    background-color: transparent;
    color: var(--main-2);
}
.chat__bottom > .inp-msg::placeholder {
    color: var(--background-2);
    font-weight: 600;
}

@media only screen and (max-width: 700px){
    .chat {
        display: flex;
        flex-direction: column;
    }
    .chat__flex fieldset {
        width: 100%;
        padding: 10px;
        border: 1px solid var(--background-3);
        border-radius: 8px;
        color: var(--white-1);
        font-size: 10pt;
    }
}

@media only screen and (min-width: 768px){
    .chat__right {
        display: block;
    }
}
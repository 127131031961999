/* Estilização principal do Confirm */
.confirm-alert {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--alert-success-2);
    z-index: 1001;
    text-align: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 90%;
    max-width: 400px;
}

/* Fundo escurecido */
.confirm-alert__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    backdrop-filter: blur(2px);
    transition: all .2s ease;
}

.confirm-alert__text {
    padding: 10px;
}

/* Texto do Confirm */
.confirm-alert__text p {
    font-size: 1.2rem;
    color: var(--background-1);
}

.confirm-alert__text div {
    font-size: 2rem;
    transition: all .6s ease;
}

/* Botões */
.confirm-alert__button {
    display: flex;
    justify-content: space-between;
}

.confirm-alert__button button {
    flex: 1;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 0px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Botão de confirmação */
.confirm-alert__button button:first-child {
    background-color: #4caf50;
    color: white;
}

.confirm-alert__button button:first-child:hover {
    background-color: #45a049;
}

/* Botão de cancelamento */
.confirm-alert__button button:last-child {
    background-color: var(--alert-fail-2);
    color: white;
}

.confirm-alert__button button:last-child:hover {
    background-color: var(--alert-fail-1);
}

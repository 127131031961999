.hero {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 220px 0px;
}

.hero__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.hero__title {
    max-width: 100%;
}

.hero__title h1{
    font-size: 4em;
    color: var(--white-1);
}

.hero__subtitle {
    max-width: 100%;
}

.hero__subtitle p {
    font-size: 1.8em;
    font-weight: 100;
    color: var(--white-1);
}

.hero__cta {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.hero__create {
    background-color: var(--main-1);
    color: var(--background-1);
    padding: 10px 20px;
    font-size: 15pt;
    border-radius: 4px;
    transition: all .2s ease;
}

.hero__create:hover {
    background-color: var(--main-2);
    color: var(--babckground-1);
    text-decoration: none;
}

.hero__sobre {
    color: var(--main-1);
}

.hero__sobre:hover {
    color: var(--main-2);
}

@media only screen and (max-width: 660px) {
    .hero {
        padding: 50px 30px;
    }
}
.upload__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 15px;
}

.upload__btn__add {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.upload__class {
    width: 250px;
    display: block;
}

.class {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--background-1);
    border-right: 1px solid var(--background-3);
}

.class__title {
    flex: 0;
    padding: 10px;
    border-bottom: 1px solid var(--background-3);
    color: var(--button-2);
    font-family: 1.3em;
}

.class__items {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.class__d__items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid var(--background-3);
}

.class__d__items > p {
    color: var(--white-1);
}

.class--item {
    width: 100%;
    padding: 8px;
    font-size: 1.2em;
    text-align: left;
    background-color: var(--background-0);
    border: 1px solid var(--background-3);
}

.active__class--item {
    width: 100%;
    padding: 8px;
    font-size: 1.3em;
    text-align: left;
    color: var(--background-1);
    background-color: var(--button-1);
    border: 1px solid var(--button-1);
    cursor: default;
    pointer-events: none;
}

.class--item:hover {
    background-color: var(--background-3);
}

.class__addBtn {
    display: flex;
    flex-direction: row;
    font-size: 1.3em;
    padding: 10px;
    margin-top: 10px;
    border-radius: 0px;
    background-color: var(--transparent);
    color: var(--button-1);
}

.class__addBtn:hover {
    color: var(--background-1);
    background-color: var(--button-1);
}

.class__add {
    flex: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.class__add__input { width: 100%;}
.class__add__input input { 
    width: 100%; 
    height: auto;
    padding: 10px;
    font-size: 1.3em;
    outline: none;
    background-color: var(--background-0);
    border-right: none;
    border-left: none;
    border-top: 1px solid var(--background-3);
    border-bottom: 1px solid var(--background-3);
    color: var(--white-1);
}

.class__add__btns {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 12pt;
}

.class__add__btns button{
    display: flex;
    flex-direction: column;
    width: auto;
    font-size: 1.4em;
    transition: all .2s ease;
    border-radius: 0px;
    text-align: center;
}

.class__add__btns button:first-child{
    background-color: var(--button-2);
    color: var(--background-1);

}
.class__add__btns button:first-child:hover {
    background-color: var(--alert-fail-2);
    color: var(--white-1);
}

.class__add__btns button:last-child{
    flex: 1;
    text-align: center;
    background-color: var(--button-1);
    color: var(--background-1);

}
.class__add__btns button:last-child:hover {
    background-color: var(--alert-success-2);
    color: var(--background-1);
}

.class__delete {
    font-size: 2em;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 0px;
    background-color: var(--background-3);
    color: var(--white-1);
}

.class__delete:hover {
    background-color: var(--alert-fail-2);
}

.upload__class__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.add__text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 50px 0px;
}

.add__text h1{
    font-size: 20pt;
    color: var(--main-2);
}

.add__text h2 {
    font-size: 15pt;
    color: var(--white-1);
    font-weight: 200;
}

.up__btn__add__new__service {
    position: sticky;
    bottom: 10px;
    z-index: 1;
    padding: 10px;
    border: 1px solid var(--button-1);
    background-color: var(--background-1);
    color: var(--button-1);
    border-radius: 4px;
    transition: all .2s ease;
}

.up__btn__add__new__service:hover {
    background-color: var(--button-1);
    color: var(--background-1);
}

/* Animação de entrada e saída */
.up__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.upload__btn__new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background-1);
    border-radius: 8px;
    padding: 20px;
    height: fit-content;
}

.upload__btn__add {
    max-width: 600px;
}

.upload__btn__new form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.upload__btn__new .upload__add {
    background-color: var(--main-2);
}

.upload__btn__new .upload__add:hover {
    background-color: var(--main-1);
}

.upload__btn__new__fields {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.upload__btn__new__fields input,
.upload__btn__new__fields textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--background-3);
    border-radius: 5px;
    background-color: var(--background-1);
    font-size: 13pt;
    text-align: center;
    color: var(--white-1);
    outline: none;
}

.upload__btn__new__fields input::placeholder,
.upload__btn__new__fields textarea::placeholder {
    color: var(--button-2);
}

.upload__btn__new__fields input:hover::placeholder,
.upload__btn__new__fields textarea:hover::placeholder {
    color: var(--link-1);
}

.desc__product {
    border: 1px solid var(--background-3);
    padding: 8px;
    font-size: 13pt;
    outline: none;
    border-radius: 5px;
    background-color: var(--background-1);
    text-align: center;
    color: var(--white-1);
}

.desc__product::placeholder {
    color: var(--button-2);
}

.desc__product:hover::placeholder {
    color: var(--link-1);
}

.upload__btn__new__fields input[type="file"] {
    display: none;
}

.upload__btn__new__fields label {
    display: block;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-2);
    font-size: 20px;
}

.upload__btn__new__fields label:hover {
    background-color: var(--background-3);
}

.upload__btn__new__fields .upload__image__preview {
    max-width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 5px;
}

.upload__btn__new__fields textarea {
    height: 120px;
    resize: none;
}

button,
.ButtonLink {
    padding: 12px 24px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

button.enabled,
.ButtonLink {
    background-color: var(--main-2);
    color: white;
}

button.enabled:hover,
.ButtonLink:hover {
    background-color: var(--main-3);
}

button.disabled {
    color: var(--background-1);
    cursor: not-allowed;
}

.arrow {
    display: none;
}

/* Responsividade */
@media only screen and (max-width: 600px) {
    .upload__container {
        width: 95%;
        align-items: center;
    }

    .up__btn {
        transform: none !important;
        opacity: 1 !important;
    }
    
    .upload__btn__new {
        padding: 15px;
    }

    .upload__btn__new form {
        gap: 15px;
    }

    .upload__btn__new__fields {
        gap: 15px;
    }

    .upload__btn__new__fields input,
    .upload__btn__new__fields textarea {
        font-size: 1.1em;
    }
}

@media only screen and (max-width: 660px) {
    .upload__class__mobile {
        position: fixed;
        width: 85%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        z-index: 1000;
        
    }

    .upload__class__container{
        width: 100%;
        padding: 0px;
    }

    .arrow {
        position: absolute;
        width: fit-content;
        right: 0;
        bottom: 53px;
        font-size: 2.5em;
        background-color: var(--background-3);
        border-radius: 0px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        margin-right: -40px;
        color: var(--white-1);
    }

    .upload__class__mobile > .class {
        flex: 1;
        background-color: var(--menu);
    }

    .upload__class__height{
        height: 50px;
        background-color: var(--menu);
        border-right: 1px solid var(--background-3);
    }
}

@media only screen and (max-width: 360px) {
    .up__btn {
        padding: 0;
    }
}
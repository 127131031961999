.how {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 150px 0px;
    border-top: 1px dashed var(--background-3);
}

.how__anim {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.how h2 {
    font-size: 2.5em;
    color: var(--main-1);
}

.how p {
    color: var(--white-1);
    font-size: 14pt;
}

.steps {
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
}

.steps svg {
    font-size: 5.8em;
    padding: 0;
    color: var(--link-1);
}

.step {
    flex: 1;
}

.step img {
    width: 100%;
}

.arrow-down { display: none; }

@media only screen and (max-width: 768px) {
    .steps {
        display: flex;
        flex-direction: column;
        width: 98%;
    }
    
    .arrow-right { display: none; }
    .arrow-down { 
        display: block; 
    }
}
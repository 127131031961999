.service {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.service__card {
    width: 300px;
    height: fit-content;
    background-color: var(--background-3);
    color: var(--white-1);
    border-radius: 10px;
    font-size: 12pt;
    border: 1px solid var(--background-3);
}


.service__card__image {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.service__card__desc {
    display: flex;
    flex-direction: column;
}

.service__card__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.service__card__desc--item {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 3px 10px;
    justify-content: space-between;
}

.service__card__desc--item h2 {
    color: var(--main-2);
    font-size: 1.7em;
}

.service__card__desc--item p {
    font-size: 1.2em;
}

.service__card__price {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.service__card__price--item {
    width: 100%;
    font-size: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
}

.service__card__price--item span {
    font-size: 1.5em;
    font-weight: 900;
}


.service__btn__edit {
    position: absolute;
    z-index: 100;
    padding: 0;
    max-width: 277px;
    display: flex;
    flex-direction: row;
    opacity: 0;
    gap: 10px;
    justify-content: space-between;
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
}
    .service:hover .service__btn__edit {
        opacity: 1;
    }
    .s__btn__edit {
        background-color: var(--background-3);
        border: 1px solid var(--white-1);
        color: var(--white-1);
        font-size: 1.3em;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 3px 10px;
    }
    .s__btn__edit:hover {
        background-color: var(--main-2);
    }
.service__btn__edit .s__btn__edit:last-child {
    padding: 0px 4px;
}

.s__btn__delete {
    padding: 0;
    font-size: 1.8em;
    color: var(--white-1);
    background-color: var(--alert-fail-2);
    border: 1px solid var(--white-1);
    display: flex;
    flex-direction: column;
    transition: all .2s ease;
}

.s__btn__delete:hover {
    background-color: var(--main-2);
}


.service__edit {
    width: 100%;
    background-color: var(--menu);
    border: none;
    color: var(--button-1);
    padding: 1px 7px;
    font-size: 1.4em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border-radius: 3px;
}

.service__edit:focus {
    outline: 1px solid var(--button-2);
}

.service__card__image__edit {
    position: absolute;
    font-size: 6em;
    z-index: 50;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--transparent-2);
    border-radius: 10px;
}

.service__card__image__edit:hover {
    background-color: var(--button-2);
    cursor: pointer;
}

.service__card__image__src {
    display: none;
}

.upload__service__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 20px;
}

.upload__service__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    gap: 10px;
    font-size: 12pt;
}

.upload__service__title p {
    font-size: 1.2em;
    color: var(--white-1);
}

.upload__service__title h2 {
    font-size: 2em;
    color: var(--main-2);
}

.service__card__infos {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.service__card__infos .name {
    color: var(--main-2);
    font-size: 1.5em;
}

.service__card__infos .desc {
    color: var(--white-1);
    font-size: 1.2em;
}

.service__card__infos .price {
    font-size: 1.5em;
    font-weight: bold;
}

.service__card__editEnable {
    padding: 5px;
}

.service__card__editEnable label {
    width: 100%;
    border-radius: 10px;
}

.service__card__editEnable label img {
    width: 100%;
    border-radius: 10px;
    transition: all .3s ease;
}

.service__card__editEnable label:hover img {
    opacity: 0.5;
    filter: blur(5px);
    cursor: pointer;
}

.service__editEnable {
    gap: 15px;
}

.edit__specific__btns {
    position: absolute;
    z-index: 100;
    margin: 10px;
    gap: 10px;
}

.edit__specific__btns .btn--close {
    background-color: var(--alert-fail-2) !important;
    color: var(--white-1) !important;
}

.edit__specific__btns .btn--close:hover {
    background-color: var(--alert-fail-1) !important;
}

.edit__specific__btn {
    padding: 3px 5px;
    background-color: var(--main-2);
    color: var(--white-1);
    display: flex;
    flex-direction: column;
}

.edit__specific__btn:hover {
    background-color: var(--main-1);
}

.sc__title,
.sc__desc,
.sc__price {
    width: 100%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-color: var(--background-1);
    border: 1px solid var(--background-3);
    color: var(--white-1);
    outline: none;
    transition: all .1s ease;
    border-radius: 10px;
    padding: 3px 9px;
}

.sc__title:focus,
.sc__desc:focus,
.sc__price:focus {
    outline: 1px solid var(--main-2);
    color: var(--main-2);
}

.sc__title {
    position: relative;
    font-size: 1.7em;
    font-weight: 900;
}

.sc__desc {
    position: relative;
    font-size: 1.2em;
    resize: none;
}

.sc__price {
    font-size: 1.7em;
    font-weight: 900;
}

.up__save__btn__service {
    background-color: var(--main-2);
    color: var(--background-1);
    font-weight: bold;
}

.up__save__btn__service:hover {
    background-color: var(--main-1);
    font-weight: bold;
}

.file__service__sel { display: none;}

.service__region__edit {
    position: sticky;
    bottom: 10px;
}

@media only screen and (max-width: 600px) {
    .service {
        display: grid; /* Define o layout como grid */
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        gap: 15px;
    }
    .service__editEnable {
        gap: 15px;
        margin-bottom: 55px;
    }
    .service__card {
        width: 100%;
        max-width: 100%;
    }
    .service__btn__edit {
        opacity: 1;
    }
}

@media only screen and (max-width: 360px) {
    .service {
        width: 100%;
        flex-direction: column;
        margin: 0;
    }
}
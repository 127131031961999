.verify__number {
    display: flex;
    flex-direction: column;
    width: 450px;
    gap: 15px;
}

.verify__number h3 {
    font-size: 1.5em;
    color: var(--main-2);
}

.verify__number p {
    color: var(--white-1);
    font-size: 1.3em;
}

.verify__button {
    background-color: var(--main-2);
    color: var(--background-1);
    font-weight: bold;
}
.verify__button:hover {
    background-color: var(--main-1);
}

.verify__input {
    padding: 10px;
    font-size: 2em;
    background-color: var(--menu);
    border: 1px solid var(--background-3);
    border-radius: 5px;
    color: var(--main-2);
}
.verify__input:focus {
    outline: 1px solid var(--main-2);
}
.verify__input::placeholder {
    color: var(--button-2);
}
.verify__input:hover::placeholder {
    color: var(--button-1);
}
:root {
  --menu: #10121a;

  --main-11: #373237;
  --main-22: #221E22;
  --main-1: #ECA72C;
  --main-2: #EE5622;
  --main-3: #ee552243;

  --purple-1: #31263E;
  --purple-2: #44355B;
  
  --white-1: #e5e5e5;
  --white-2: #f3f3f3;
  --white-3: #d8d8d8;

  --link-1: #61dafb;
  --link-2: #5bc1dd;

  --background-0: #1b1e2c;
  --background-1: #161925;
  --background-2: #23395B;
  --background-3: #1f2332;

  --transparent-1: #161925cb;
  --transparent-2: #0868884e;

  
  --button-1: #06AED5;
  --button-2: #086788;
  
  --alert-success-1: #7BF1A8;
  --alert-success-2: #4fcd7f;
  --alert-success-3: #35975b;

  --alert-fail-1: #DB5461;
  --alert-fail-2: #bc4853;

  /* gradients */
  --gradient-red: #ff5858;
  --gradient-yellow: #f09819;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--background-1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--background-2);
  border-radius: 10px;
  border: 2px solid var(--background-3); 
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-display: swap;
}


html, body {
  background-color: var(--background-1);
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: 12pt;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; /* Adicione Arial como fallback */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html > span {
  color: var(--main-2);
}

/* Gradient Configuration */
.gradient-bg {
  background: -webkit-linear-gradient(-60deg, var(--gradient-red) 0%, var(--gradient-yellow) 100%);
}

fieldset {
  background-color: var(--background-0);
  border: 1px solid var(--background-3);
  border-radius: 8px;
  padding: 10px;
}

p {
  font-size: 13pt;
}

.brand {
  color: var(--white-1);
  font-size: 1.9em;
}

.brand > span {
  color: var(--main-2);
}

h1, h2, h3, h4, h5, h6 { 
  font-size: 20pt;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
}

textarea {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}


/* FONTS */

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

@media only screen and (max-width: 700px) {
  ::-webkit-scrollbar {
    width: 0;  /* Remove a largura da barra de scroll */
    height: 0; /* Remove a altura da barra de scroll */
    background: transparent; /* Torna o fundo da barra de scroll transparente */
  }

  /* Para Firefox */
  html {
    scrollbar-width: none; /* Oculta a barra de scroll */
  }

  /* Para garantir que funcione em todos os navegadores */
  body {
    -ms-overflow-style: none; /* Para Internet Explorer e Edge */
  }
}
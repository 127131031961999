
.alert__container {
    display: flex;
    flex-direction: column;
}

.alert__message__title,
.alert__message__text {
    font-size: 13pt;
    padding: 8px;
}

.alert__message__title > h3 {
    font-size: 14pt;
    color: var(--background-1);
}

.alert-fail > .alert__message__title {
    background-color: var(--alert-fail-2);
}

.alert__message__text > p {
    font-size: 12pt;
    color: var(--background-1);
}

.alert-fail > .alert__message__text {
    background-color: var(--alert-fail-1);
    border: 2px solid var(--alert-fail-2);
}

.alert-success > .alert__message__title {
    background-color: var(--alert-success-2);
}

.alert-success > .alert__message__text {
    background-color: var(--alert-success-1);
    border: 2px solid var(--alert-success-2);
}

.alert__container a{
    color: var(--button-1);
}

@media only screen and (min-width: 500px) {
    .alert__message {
        background-color: transparent;
    }
}
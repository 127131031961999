.complete {
    position: fixed;
    z-index: 1001;
    background-color: var(--background-1);
    color: var(--white-1);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.complete__message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.complete__message > h2 {
    font-size: 30pt;
}

.complete__message > p {
    font-size: 12pt;
    
}

.complete__message > button {
    background-color: var(--main-2) !important;
    transition: all .2s ease;
}

.complete__message > button:hover {
    background-color: var(--main-1) !important;
}

@media only screen and (max-width: 500px) {
    .complete__message {
        padding: 30px;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    
}
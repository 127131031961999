/* Formulário principal */
.form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: var(--background-1);
    border-radius: 8px;
    max-width: 600px;
}

.title__ui {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white-1);
    padding: 25px 0px;
    gap: 10px;
}

.title__ui__text {
    text-align: center;
}

.form__section {
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 8px;
}

.upgrade {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12pt;
    color: var(--white-1);
}

.upgrade__item {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.acc {
    background-color: var(--transparent);
    color: var(--white-1);
    padding: 4px 9px;
    border-radius: 50px;
}

.acc:hover {
    background: var(--main-1);
}

.acc__active {
    background-color: var(--main-2);
    border-radius: 50px;
    color: var(--background-1);
    font-weight: bold;
}

.acc__active:hover {
    cursor: default;
    pointer-events: none;
    background-color: var(--main-2);
    
}

.form__section__div {
    display: flex;
    flex-direction: row;
}

.form__section__divide {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0px 5px;
}

.form__section-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--main-2);
    margin-bottom: 10px;
    padding-bottom: 5px;
}

.form__section label {
    font-size: 10pt;
    color: var(--white-1);
}

/* Campos do formulário */
.form__input {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    margin-bottom: 15px;
    border: 1px solid var(--button-2);
    color: var(--link-1);
    background-color: transparent;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
}

.form__input:disabled {
    color: var(--link-1);
    background-color: var(--background-3) !important;
}

.form__input::placeholder {
    color: var(--button-2);
}

.form__input:focus {
    border: 1px solid var(--link-1);
    background-color: var(--background-3);
}

.form__input--dias {
    height: auto;
    padding: 8px;
}

/* Estilo dos checkboxes */
.form__input--abre-feriados,
.form__input--possui-investimento {
    width: auto;
    margin-right: 5px;
}

/* Textarea para campos maiores */
textarea.form__input {
    resize: vertical;
    min-height: 80px;
}

/* Botões */
.form__section--buttons {
    position: sticky;
    bottom: 0px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0px;
    border-radius: 0px;
    padding: 10px;
    background-color: var(--background-1) !important;
    gap: 10px;
}

.form__section--buttons button {
    padding: 4px 10px;
}

.form__section__edit {
    width: fit-content;
    background-color: var(--background-1);
    color: var(--main-2);
    border-radius: 0px !important;
    padding: 12px !important;
    margin: 0px !important;
    font-size: 1.5em;
    text-decoration: none;
    text-transform: uppercase;
}

.form__section__edit svg {
    font-size: 1.1em;
}
.form__section__cancel {
    background-color: transparent;
    color: var(--white-1);
}
.form__section__cancel:hover {
    color: var(--button-1);
}
.form__section__save {
    background-color: var(--main-2);
    color: var(--background-1);
    font-weight: bold;
}
.form__section__save:hover {
    background-color: var(--main-1);
}

.form__section__save__off {
    display: none;
    width: fit-content;
    background-color: var(--link-1);
    color: var(--background-1);
    margin-left: 15px;
}

.field__alerts {
    position: sticky;
    top: 50px;
    background-color: transparent;
    border: none;
}

.field__alerts > .alert__message{
    position: relative;
    margin: 0;
    padding: 0;

}

/* Responsividade */
@media (max-width: 600px) {
    .form {
        padding: 10px;
        width: 100%;
    }
    
    .upgrade {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }

    .form__section-title {
        font-size: 1rem;
    }

    .form__input {
        font-size: 0.9rem;
    }

    .form__submit {
        width: 100%;
    }
}
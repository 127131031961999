.item {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden; /* Evita que elementos fora da área visível apareçam */
}

.item__container {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden; /* Garante que apenas o slide visível seja mostrado */
    position: relative;
}

.item__slider {
    display: flex;
    width: 100%;
    transition: transform 0.5s ease-in-out; /* Transição suave */
}

.item__service {
    flex: 0 0 100%; /* Cada slide ocupa 100% do espaço visível */
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease; /* Suaviza mudanças de opacidade */
}



.item__service.active {
    opacity: 1;
    transform: translateX(0); /* Centraliza o slide ativo */
    display: flex; /* Garante que o elemento ativo seja exibido */
}
.item__service.inactive {
    opacity: 0;
    pointer-events: none; /* Garante que itens inativos não possam ser clicados */
}


.item__service__infos {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 14pt;
    text-align: center;
    margin: 10px 0;
    justify-content: center;
    align-items: center;
}

.item__image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Efeito de sombra */
    margin: 10px 0;
}

.image__click {
    background-color: var(--background-1);    
    color: var(--main-2);
}
/* Estilização das setas de navegação */
.item__arrow__left,
.item__arrow__right {
    position: absolute;
    font-size: 2em;
    cursor: pointer;
    border: 1px solid var(--background-3);
    border-radius: 50%;
    color: var(--main-2);
    background-color: var(--background-3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.item__arrow__left {
    left: 10px; /* Posiciona a seta à esquerda */
}

.item__arrow__right {
    right: 10px; /* Posiciona a seta à direita */
}

.item__arrow__left:hover,
.item__arrow__right:hover {
    background-color: var(--main-2);
    border: 1px solid var(--main-2);
    color: var(--background-1);
}

.infos__item__show {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.item__service__title {
    font-size: 14pt;
    color: var(--white-1);

}

.item__service__desc {
    font-size: 13pt;
    color: var(--white-1);

}

.item__service__price {
    font-size: 14pt;
    color: var(--white-1);
}

/* Responsividade */
@media (max-width: 768px) {
    .item__service__infos {
        font-size: 12pt;
    }

    .item__arrow__left,
    .item__arrow__right {
        font-size: 1.5em;
        padding: 8px;
    }
}

@media only screen and (min-width: 768px){
    .item {
        max-width: 600px;
    }
    .item__image {
        max-width: 600px;
        
    }
}

.install-app {
    display: none;
}

@media only screen and (max-width: 500px) {
    .install-app {
        width: 90%;
        display: flex; /* Apenas uma declaração de display */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
    }
    
    .install-app p {
        color: var(--white-1); /* Verificado com index.css */
    }
    .install-app button {
        color: var(--background-1);
        font-size: 15pt;
        background-color: var(--main-2);
        padding: 5px;
        border-radius: 8px;
        border: 1px solid var(--background-3);
    }
}

.post {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.post__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.post__image {
    width: 100%;
    height: auto;
    border-radius: 5px;
    
}

.post__title {
    width: 100%;
    height: auto;
    padding: 10px;
    border-radius: 5px;
    font-size: 12pt;
    background-color: var(--menu);
    border: 1px solid var(--background-3);
    color: var(--white-1);
}

.post__title::placeholder,
.post__text::placeholder {
    color: var(--button-2);
}

.post__text {
    width: 100%;
    height: auto;
    min-height: 50px;
    max-height: 200px;
    padding: 10px;
    font-size: 12pt;
    background-color: var(--menu);
    border: 1px solid var(--background-3);
    color: var(--white-1);
    border-radius: 5px;
    resize: none;
    outline: none;
}

.post__title:focus,
.post__text:focus {
    outline: 1px solid var(--main-2);
    color: var(--main-2);
}

.post__text::placeholder {
    color: var(--button-2);
}

.post__button {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
}

.post__button button{
    display: flex;
    flex-direction: column;
    padding: 0px;
}

.post__btn__input {
    display: none;
}
.post__btn {
    background-color: var(--background-0);
    color: var(--white-1);
    font-size: 1.8em;
    transition: all .2s ease;
}
.post__btn:hover {
    color: var(--button-1);
}

.post__button--sendPost {
    background-color: var(--background-0);
    font-size: 2.5em;
    color: var(--button-1);
}
.post__button--sendPost:hover {
    color: var(--main-2);
}

.post__button--addImage {
    cursor: pointer;
}

.post__button--addImage svg{
    font-size: 1.8em;
}

.post__addPost {
    background-color: var(--button-2);
    margin-bottom: 20px;
}

.post__addPost:hover {
    background-color: var(--button-1);
}
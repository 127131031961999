.forms {
    display: flex;
    flex-direction: row;
    font-size: 12pt;
    color: var(--main-0);
    max-width: 60%;
    transition: all .2s ease;
}

.forms__container {
    display: flex;
    flex-direction: row;
}

.forms__left {
    flex: 2;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white-1);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

}

.forms__left__motion {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.login {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
    .login__input {
        width: 100%;
        padding: 10px 0px;
        display: flex;
        flex-direction: column;
        transition: all .3s ease;
    }
    .forms__input {
        width: 100%;
        color: var(--main-0);
        border-radius: 3px;
        background-color: var(--white-3);
        border: none;
    }
        .forms__input:focus {
            background-color: var(--white-2);
        }
    .login__btn {
        margin-top: 10px;
    }
    .login > h2 {
        font-size: 25pt;
    }
    .register > h2 {
        font-size: 25pt;
    }


.forms__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: var(--white-1);
}
    .forms__right > h1 {
        font-size: 30pt;
    }
        .forms__right > h1 > span {
            color: var(--main-0);
        }
    .forms__right > p {
        font-size: 12pt;
    }
        .forms__right > p > b {
            color: var(--main-0);
        }

.register {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
}
    .register__input {
        width: 100%;
        padding: 10px 0px;
    }
    .register__btn {
        margin-top: 10px;
    }

.btn__login {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--main-1);
    font-weight: bold;
    transition: all .3s ease;
    color: var(--background-1);
}

.btn__login:hover {
    background-color: var(--main-2);
    color: var(--white-1);
}

@media only screen and (max-width: 1060px) {
    .forms {
        max-width: 90%;
    }
}

@media only screen and (max-width: 800px) {

    .forms__left {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0px;
        padding: 20px;
    }
    .forms__right {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 20px;
    }

    
    .forms__container {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (min-width: 1300px) {
    .forms {
        max-width: 35%;
    }
}
.myPost {
    padding: 10px 0px;
}

.myPost__content .tags {
    display: flex;
    flex-direction: row;
    gap: 7px;
    padding: 10px 10px;

}

.myPost__content .content {
    color: var(--white-1);
    padding: 0px 10px;
}

.avatar-closed {
    display: flex;
    flex-direction: row;
}

.avatar-mdClose {
    display: flex;
    flex-direction: column;
    font-size: 1.8em;
    background-color: var(--tranparent);
    color: var(--white-1);
    padding: 0px;
    transition: all .2s ease;
}

.avatar-mdClose:hover {
    color: var(--main-2);
    rotate: 90deg;
}

.myPost__content .content .reaction {
    padding: 10px 0px;
}

.reaction {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.reaction__btn {
    display: flex;
    flex-direction: row;
    padding: 0px;
    background-color: transparent;
    color: var(--white-1);
    align-items: center;
}

.reaction__btn > svg {
    font-size: 1.8em;
}

.reaction__btn > p {
    font-size: 1em !important;
}

.reaction__btn:hover {
    color: var(--main-2);
}

.reaction__btn:disabled {
    color: var(--button-2);
    pointer-events: none;
    cursor: default;
}

.myPost__content .content {
    font-size: 12pt;
}

.myPost__content .content p {
    font-size: 1em;
}

.myPost__content .content p b {
    color: var(--button-1);
}

.myPost__content {
    padding: 0px;
}

.post__content__image {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
}

.post__content__image > img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.tags > p {
    font-size: 10pt;
    color: var(--button-1);
}

.avatar {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.avatar-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.avatar-image {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-radius: 5px;
}

.avatar-image > img {
    width: 30px;
    height: 30px;
    border-radius: 5px;
}

.avatar-info p {
    color: var(--main-1);
}

.post__edit {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.post__btnSend {
    width: fit-content;
    height: auto;
    font-size: 1.9em;
    padding: 0px 3px;
    color: var(--background-0);
    background-color: var(--alert-success-2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.post__btnSend:hover {
    background-color: var(--alert-success-1);
}

.comment {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.comment__sendComment {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    gap: 10px;
    padding: 10px;
    font-size: 12pt;
}

.comment__sendComment > textarea {
    flex: 1;
    background-color: var(--background-3);
    border: 1px solid var(--background-1);
    border-radius: 10px;
    padding: 10px;
    height: 46px;
    font-size: 1em;
    color: var(--white-1);
    resize: none;
}

.comment__sendComment > textarea:focus {
    outline: 1px solid var(--main-2);
    color: var(--main-2);
}

.comment__sendComment > textarea::placeholder {
    color: var(--button-2);
}

.comment__sendComment > textarea:hover::placeholder {
    color: var(--button-1);
}

.comment__sendComment > button {
    flex: 0;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 3em;
    color: var(--main-1);
    background-color: var(--transparent);
}

.comment__sendComment > button:disabled {
    color: var(--menu);
}

.comment__viewComment {
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
}
.LikeSpinner {
    animation: spin 2s infinite linear; /* Gira continuamente a cada 2 segundos */
}

@keyframes spin {
    from {
        transform: rotate(0deg); /* Início da rotação */
    }
    to {
        transform: rotate(360deg); /* Roda 360 graus */
    }
}

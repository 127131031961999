.homePage {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.homePage__middle {
    width: 600px;
    height: auto;
    display: flex;
    flex-direction: column;
}

.homePage__left,
.homePage__right {
    flex: 2;
}

.homePage__dv {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
}

.homePage__dv__textarea {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.homePage__dv__textarea > legend {
    font-size: 10pt;
    font-weight: bold;
    color: var(--main-2);
}

.homePage__filter__tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 12pt;
}

.title__tags {
    font-size: 1em;
    color: var(--white-1);
}

.homePage__filter__faCheck {
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    justify-content: center;
    background-color: var(--background-0);
    border: 1px solid var(--alert-success-2);
    color: var(--alert-success-2);
}

.homePage__filter__faCheck:hover {
    background-color: var(--alert-success-2);
    color: var(--background-0);
}

.homePage__filter__mdClose {
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    justify-content: center;
    background-color: var(--background-0);
    border: 1px solid var(--alert-fail-2);
    color: var(--alert-fail-2);
}

.homePage__filter__mdClose:hover {
    background-color: var(--alert-fail-2);
    color: var(--background-0);
}

.homePage__dv__textarea > .homePage__filter__tags > textarea {
    width: 100%;
    height: 45px;
    max-height: 100px;
    padding: 10px;
    font-size: 1em;
    background-color: var(--background-0);
    border: 1px solid var(--background-0);
    border-radius: 10px;
    resize: none;
    color: var(--white-1);
}

.homePage__dv__textarea > .homePage__filter__tags > textarea:focus {
    outline: 1px solid var(--main-2);
    color: var(--main-2);
}

.homePage__dv__textarea > .homePage__filter__tags > textarea::placeholder {
    color: var(--button-2);
}

.homePage__dv__textarea > .homePage__filter__tags > textarea:hover::placeholder {
    color: var(--button-1);
}

.homePage__tags {
    width: 100%;
    height: auto;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.homePage__tags span {
    font-size: 12pt;
    background-color: var(--main-2);
    color: var(--background-1);
    padding: 0px 5px;
    border-radius: 50px;
}

.home__middle {
    flex: 3;
}

.side {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.side > button {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 8px;
    background-color: var(--background-0);
    border: 1px solid var(--background-3);
    color: var(--button-2);
}

.side > button:hover {
    background-color: var(--main-2);
    color: var(--background-1);
}
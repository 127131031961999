/* Container principal */
.store-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: var(--white-1);
    transform: all .3s ease;
}

/* Título da loja */
.store-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: var(--main-2);
}

/* Grade de itens */
.store-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
    transition: all .3s ease;
}

/* Cartão de cada serviço */
.store-card {
    width: auto;
    height: fit-content;
    background-color: var(--background-0);
    border: 1px solid var(--background-3);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: height 0.3s ease;
}

.store-card:hover {
    background-color: var(--background-3);
}

.store-click-card > .store-image{
    height: auto;
}

/* Imagem do serviço */
.store-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    transition: all .3s ease;
}

/* Título do serviço */
.store-item-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 15px;
    color: var(--main-2);
}

/* Descrição do serviço */
.store-item-desc {
    font-size: 1.2rem;
    margin: 0 15px 10px;
    color: var(--white-1);
}

/* Preço do serviço */
.store-item-price {
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--button-1);
    margin: 0 15px 10px;
}

/* Status do serviço */
.store-item-status {
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: capitalize;
    margin: 0 15px 15px;
}

/* Cores baseadas no status */
.store-item-status.ativo {
    color: var(--alert-success-2);
}

.store-item-status.inativo {
    color: #ff0000;
}

/* Mensagem quando não há itens */
.store-empty {
    text-align: center;
    font-size: 1.2rem;
    color: #888;
    margin-top: 50px;
}


.homeLink__mobile__nav {
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 100%;
    bottom: 0;
    border-top: 1px solid var(--background-3);
    background-color: var(--background-1);
    z-index: 9999;
}

.homeLink__mobile__nav > button {
    flex: 1;
    background-color: var(--background-1);
    border-radius: 0px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    align-items: center;
    color: var(--white-1);
}

.loading {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
    overflow-y: hidden;
    justify-content: center;
    align-items: center;
}

.loading > img {
    animation: spin 8s linear infinite; /* Aplica a animação */
}

@keyframes spin {
    from {
        transform: rotate(0deg); /* Inicia em 0 graus */
    }
    to {
        transform: rotate(360deg); /* Gira até 360 graus */
    }
}

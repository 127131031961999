.plan {
    width: 60%;
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding: 80px 0px;
}

.plan > fieldset {
    flex: 1;
    padding: 20px;
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
    height: fit-content;
}

.plan:hover  fieldset {
    filter: blur(8px);
}

.plan__title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.plan__title h2{
    font-size: 5em;
    color: var(--main-2);
    transition: all .2s ease;
}


.plan__title .plan__p--animate {
    transform: translateY(-10px);
    font-size: 13pt;
    color: var(--button-1);
    text-align: center;
    transition: all .2s ease;
}

.plan__price {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--menu);
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.plan__price p {
    font-size: 15pt;
    color: var(--white-1);
    display: flex;
    flex-direction: row;
}

.plan__price p span {
    font-size: 50pt;
    font-weight: 900;
}

.plan__price .sub-price {
    font-size: 10pt;
}

.free:hover,
.standard:hover,
.premium:hover {
    filter: blur(0px) !important;
    z-index: 100;
}

/* FREE */
.free:hover .plan__title .plan__h2 {
    color: var(--background-1);
}

.free:hover {
    transform: scale(1.05);
}
.free:hover .plan__title .plan__p--animate {
    transform: translateY(-40px);
}

/* STTANDARD */
.standard {
    background-color: var(--background-3);
}
.standard:hover .plan__title .plan__h2 {
    color: var(--background-1);
}

.standard:hover {
    transform: scale(1.05);
}
.standard:hover .plan__title .plan__p--animate {
    transform: translateY(-40px);
}

/* PREMIUM */
.premium:hover .plan__title .plan__h2 {
    color: var(--background-1);
}

.premium:hover {
    transform: scale(1.05);
}
.premium:hover .plan__title .plan__p--animate {
    transform: translateY(-40px);
}

.plan__info {
    display: flex;
    flex-direction: column;
    gap: 25px;
    color: var(--white-1);
}

.plan__info .color {
    color: var(--main-1);
}

.plan__info--sub {
    padding: 5px;
    border: 1px solid var(--background-3);
    border-radius: 10px;
    background-color: var(--background-1);
}

.plan__info--success {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--alert-success-3);
    color: var(--background-1);
}

.plan__info--success p {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    justify-content: space-between;
}

.plan__info--success span {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    font-size: 1.7em;
    color: var(--background-1) !important;
}

.plan__info--attention {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--alert-fail-2);
}

.plan__info--attention p {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    justify-content: space-between;
}

.plan__info--attention span {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    font-size: 1.7em;
    color: var(--white-1) !important;
}

.plan .detail {
    background-color: var(--main-1);
    color: var(--background-1);
    padding: 0px 6px;
    border-radius: 10px;
}

.plan__btn {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
}

.plan__btn button {
    width: 100%;
    background-color: var(--button-2);
    color: var(--white-1);
    font-weight: bold;
}

.plan__btn button:hover {
    background-color: var(--button-1);
}

@media only screen and (max-width: 600px) {
    .plan {
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .plan__title .plan__p--animate {
        transform: translateY(-40px);
    }

    .plan:hover  fieldset {
        filter: none;
    }
    .free:hover,
    .standard:hover,
    .premium:hover {
        filter: none;
    }

    /* FREE */
    .free .plan__title .plan__h2 {
        color: var(--background-1);
    }

    .free .plan__title .plan__p--animate {
        transform: translateY(-40px);
    }

    .free:hover {
        transform: none;
    }

    /* STTANDARD */
    .standard {
        background-color: var(--background-3);
    }
    .standard .plan__title .plan__h2 {
        color: var(--background-1);
    }

    .standard:hover {
        transform: none;
    }
    /* PREMIUM */
    .premium .plan__title .plan__h2 {
        color: var(--background-1);
    }

    .premium {
        transform: none;
    }

}
.comment__user {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 10px;
    gap: 10px;
}

.comment__user__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    align-items: center;
    gap: 10px;
    background-color: var(--background-3);
    border-radius: 10px;
    padding: 10px;
}

.comment__user__avatar {
    flex: 0;
    display: flex;
    flex-direction: row;
    width: 40px;
    height: 40px;
    aspect-ratio: 1 / 1;
    align-items: center;
    justify-content: center;
}

.comment__user__avatar > .comment__avatar {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.comment-content {
    flex: 1 !important;
    display: flex;
    flex-direction: column;
}

.comment-user {
    color: var(--button-1);
}

.comment-author {
    color: var(--main-2);
}

.comment-text {
    color: var(--white-1);
}

.comment-reply {
    padding: 0px;
    width: fit-content;
    font-size: 10pt;
    background-color: var(--transparent);
}

.comment-reply > span {
    color: var(--button-2);
}

.comment-reply > span:hover {
    color: var(--button-1);
}

.comment-close {
    display: flex;
    flex-direction: column;
    font-size: 14pt;
}

.comment__btn__like {
    padding: 0px;
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--transparent);
    color: var(--white-1);
}


.comment__btn__like > p {
    font-size: 10pt;
}

.comment__btn__like:hover {
    color: var(--main-2);
}

.comment__btn__close {
    padding: 0px;
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--transparent);
    color: var(--white-1);
    transition: all .2s ease;
}

.comment__btn__close:hover {
    rotate: 90deg;
}
.homeLink {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.homeLink .class__title > h3{
    color: var(--main-2) !important;
}

.homeLink__title {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.homeLink__title > a {
    width: 100%;
    font-size: 1.5em;
    color: var(--white-1);
    text-decoration: none;
    outline: none;
    text-align: center;
    padding: 5px 0px;
    margin-bottom: 10px;
    transition: all .2s ease;
}

.homeLink__title > a:hover {
    background-color: var(--main-2);
    color: var(--background-1);
}

.class {
    width: auto;
    min-width: 200px;
    height: 100%;
    padding: 0px;
    border-radius: 1px solid var(--background-0);
}

.class__imgProfile {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
}

.class__imgProfile > img {
    min-width: 150px;
    max-width: 150px;
    border-radius: 50%;
    border: 5px solid var(--background-0);
}

.class__mob {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--background-3);
    z-index: 1000;
}

.class__result {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-bottom: 50px;
}

.class__result .profile {
    max-width: 650px;
    padding: 20px;
    border: 1px solid var(--background-0);
    border-radius: 30px;
}

.class__result .profile .profile__photo {
    padding: 0px;
    padding-right: 10px;

    
}

.class__height {
    height: 50px;
}
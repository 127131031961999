.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--background-1);
    padding: 1rem 0;
    position: sticky; /* Adicionado para servir de referência ao dropdown */
    top: 0;
}

.header .head {
    display: flex;
    flex-direction: row;
    min-width: 80%;
    max-width: 90%;
    justify-content: space-between;
    align-items: center;
    position: relative; /* Certifique-se de que o pai do dropdown também é relativo */
}

.header .head .head__item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.head__item .menu {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.head__item .menu__dropdown {
    display: none;
}

.head__item h2 {
    font-size: 20pt;
    color: var(--white-1);
    font-weight: bold;
}

.head__item a {
    color: var(--main-2);
    font-size: 13pt;
    padding: 10px 18px;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
}

.head__item a:hover {
    text-decoration: none;
    cursor: pointer;
    color: var(--background-1);
    background-color: var(--main-2);
}

/* Estilo do dropdown */
.head__item .dropdown {
    position: absolute;
    background-color: var(--background-1);
    border: 1px solid var(--background-3);
    z-index: 9998;
    top: 50px; /* Ajuste para garantir que o dropdown esteja visível */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.head__item .dropdown a {
    padding: 10px 20px;
    font-size: 12pt;
    color: var(--main-2);
    text-align: left;
}

.head__item .dropdown a:hover {
    background-color: var(--main-2);
    color: var(--background-1);
}

/* Responsividade */
@media only screen and (max-width: 700px) {
    .head__item .menu {
        display: none;
    }

    .head__item .menu__dropdown {
        display: block;
    }

    .head__item .dropdown a {
        width: 100%;
    }

}

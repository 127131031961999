.flow__option__center {
    flex: 1 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    position: sticky; /* Torna a div fixa em relação ao seu contêiner */
    top: 0;
}

@media only screen and (min-width: 600px) {
    .flow__footer__step {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .flow__mob__row {
        align-items: center;
        display: flex;
        flex-direction: row;
    }
    .flow__info {
        flex: 1;
    }

    .hide_sideMenu {
        display: none !important;
    }

    .toggle_sideMenu {
        display: flex;
    }

    .flow__footer__step {
        justify-content: space-between;
        z-index: 1000;
        border: 0 !important;
    }
    .flow__footer__step > button {
        flex: 1;
        border-radius: 0;
        background-color: var(--background-1);
        border-top: 1px solid var(--background-3);
        color: var(--main-2);
        font-size: 2em;
        padding: 10px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .space__header {
        height: 45px;
    }

    
}
.card__style {
    background-color: var(--background-0);
    border: 1px solid var(--background-3);
    border-radius: 5px;
    gap: 10px;
    
}

.card__item {
    width: 100%;
    justify-content: center;
    align-items: center;
}

.card__style__name {
    padding-top: 10px;
}

.card__style__name span{
    font-size: 1.3em;
    background-color: var(--main-2);
    border-radius: 50px;
    color: var(--background-1);
    font-weight: bold;
    padding: 3px 6px;
}

.card__style__title {
    color: var(--white-1);
    font-size: 2em;
    border-bottom: 1px solid var(--background-3);
    padding-bottom: 10px;
    text-align: center;
}

.card__style__text {
    padding: 0px 10px;
    color: var(--white-1);
}
.card__style__text > p {
    font-size: 1.5em;
    text-align: center;
}

.card__style__option {
    border-top: 1px solid var(--background-3);
    justify-content: space-between;
    padding: 10px;
    font-size: 1.4em;
}

.card__style__link {
    text-decoration: none;
    color: var(--main-2);
}

.card__style__link:hover {
    color: var(--button-1);
}

.card__step {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    max-width: 60%;
    min-width: 40%;
}

.card__btn {
    display: flex;
    flex-direction: column;
    width: fit-content;
    background-color: transparent;
    border: none;
    color: var(--white-1);
    padding: 0;
    font-size: 2.2em;
}

.card__btn:hover {
    background-color: var(--main-2);
    color: var(--background-1);
}

.card__item__input {
    width: 90%;
    text-align: center;
    font-size: 1.4em;
    font-weight: bolder;
    color: var(--button-1);
    background-color: var(--background-3);
    border: none;
    border-radius: 5px;
    outline: none;
}

.card__item__input__text {
    width: 95%;
    height: 100px;
    resize: none;
    text-align: center;
    font-size: 1.9em;
    color: var(--button-1);
    background-color: var(--background-3);
    border: none;
    border-radius: 5px;
    outline: none;
}

.card__item__option {
    background-color: transparent;
    border: none;
    color: var(--button-1);
    font-size: 1em;
    outline: none;
}

.card__item__option:hover {
    cursor: pointer;
}

.card__item__option > option {
    background-color: var(--background-1);
    cursor: pointer;
}

.card__btn__save {
    font-size: 1.4em;
    padding: 5px 10px;
    background-color: var(--button-1);
}

.card__btns {
    justify-content: space-between;
}

.card__btn__save:hover {
    background-color: var(--link-1);
}

.card__reset {
    display: flex;
    flex-direction: column;
    padding: 0;
    font-size: 2em;
    color: var(--white-1);
    background-color: var(--transparent);
    transition: all .2s ease;
}

.card__reset:hover {
    rotate: 90deg;
}

.card__left__btn {
    align-items: start;
}

@media only screen and (max-width: 600px) {
    .card__width {
        display: flex;
        flex-direction: column;
    }
    .card__step {
        max-width: 90%;
    }
}
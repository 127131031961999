.divide {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: var(--alert-success-2);
}

.divide__container {
    min-width: 80%;
    max-width: 90%;
    padding: 8px 0px;
    font-size: 10pt;
}
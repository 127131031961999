
.button {
    padding: 8px 8px;
    width: 100%;
    border: none;
    font-size: 12pt;
    font-weight: 600;
    text-transform: uppercase;
    background-color: var(--main-2);
    color: var(--white-1);
    border-radius: 4px;
    margin-bottom: 10px;
    transition: all .1s ease;
}

.button:hover {
    background-color: var(--main-1);
    cursor: pointer;
}

/* Button link */

.button-link {
    display: flex;
    width: fit-content;
    font-size: 12pt;
    color: var(--button-2);
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.button-link:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration: underline;
    color: var(--button-1);
}

.button-link svg {
    font-size: 10pt;
    margin-left: 5px;
}
.clients {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
}

.clients__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.clients__container > h2 {
    font-size: 18pt;
    color: var(--white-1);
}

.clients__link {
    color: var(--main-2);
}

.clients__container {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.clients__container > p {
    font-size: 14pt;
    color: var(--white-1);
    text-align: center;
}

.clients__item {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--background-3);
    border-radius: 8px;
}

.clients__dv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.clients__dv__hover:hover {
    background-color: var(--background-3);
    cursor: pointer;
}

.clients__dv__hide {
    display: none;
}

.clients__dv h3 {
    font-size: 13pt;
    color: var(--button-1);
    padding: 10px 0px;
}

.clients__dv p {
    font-size: 14pt;
    color: var(--white-1);
}

.clients__dv span {
    font-size: 10pt;
}
.message {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    transition: all .3s ease;
    justify-content: center;
    align-items: center;
}

.message__sended {
    position: relative;
    background-color: var(--main-2);
    max-width: 90%;
    padding: 5px 10px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
    font-size: 14pt;
}

.message__sended::before {
    content: "";
    width: 0;
    height: 0;
    bottom: 100%;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--main-2);
}


.message__sended p {
    color: var(--background-1);
    font-weight: 500;
    text-align: center;
}

.message__options {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.message__options button {
    color: var(--main-2);
    background-color: transparent;
    border: none;
    font-size: 13pt;
    font-weight: 600;
    padding: 0px 5px;
}
.message__options button:hover {
    background-color: var(--main-2);
    color: var(--background-1);
    border-radius: 5px;
    cursor: pointer;
}

.message__input {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.message__input__insert {
    flex: 1;
    background-color: transparent;
    border: 1px solid var(--background-3);
    padding: 10px;
    font-size: 12pt;
    color: var(--main-2);
    outline: none;
    border-radius: 8px;
}

.message__input__insert::placeholder {
    color: var(--background-2);
}

.message__input__insert:hover::placeholder {
    color: var(--button-2);
}

.message__input__submit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: var(--background-3);
    border: 1px solid var(--background-3);
    font-size: 15pt;
    padding: 10px;
    color: var(--background-2);
    height: 100%;
    transition: all .3s ease;
    outline: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    -webkit-transition: all .3s ease;
}

.message__input__submit:hover {
    color: var(--background-1);
    background-color: var(--main-2);
    cursor: pointer;
}



@media only screen and (max-width: 700px){
    .chat__mid {
        width: 100%;
    }
}
/* Header */
.container__lmr header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0px 20px;
    background-color: var(--background-1);
    border-bottom: 1px solid var(--background-3);
    z-index: 9999;
    position: relative;
}

.menu__header {
    width: fit-content;
    display: flex;
    flex-direction: row;
    font-size: 12pt;
}

/* Menu Dropdown */

.menu__dropdown {
    display: none;
}

.dropmenu {
    width: auto;
    height: 50px;
    background-color: var(--background-1);
    padding: 0px 20px;
    font-size: 25pt;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-2);
    z-index: 1;
}

.dropmenu:hover {
    cursor: pointer;
    background-color: var(--background-3);
}

/* Estilo do dropdown */
.dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style-type: none;
    background-color: var(--background-1);
    border: 1px solid var(--background-3);
    z-index: 9998; /* Certificar que o dropdown também esteja acima de outros elementos */
    right: 0;
    top: 100%; /* Faz o dropdown abrir abaixo do menu */
}

/* Opções do Menu */
.option__menu {
    width: auto;
    font-size: 1.1em;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 0px 15px;
    height: 50px;
}

/* Ícones dentro das opções */
.option__menu svg {
    font-size: 1.1em;
}

/* Botão Desabilitado */
.header__btn--disabled {
    color: var(--background-1);
    background-color: var(--main-2);
    text-decoration: none;
    pointer-events: none;
}

.header__btn--disabled:hover {
    color: var(--background-1) !important;
    cursor: default !important;
    text-decoration: none;
}

@media only screen and (max-width: 800px) {
    .menu__header {
        display: none;
    }
    .menu__dropdown {
        display: flex;
    }
}
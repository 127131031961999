.container {
    position: absolute;
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
}

.container__lmr {
    display: flex;
    flex-direction: column;
}

.container__left {
    position: sticky;
    top: 0;
    z-index: 10000;
    display: flex;
    flex-direction: column;
}

.container__left--menu {
    position: sticky;
    top: 50px;
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.container__left--menu > fieldset {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.container__left--menu .menu__btn {
    color: var(--white-1);
    text-decoration: none;
    padding: 3px 6px;
}

.container__left--menu .menu__btn:hover {
    color: var(--link-1);
    background-color: var(--background-2);
}

.container__middle {
    flex: 1 !important;
    display: flex;
    flex-direction: column;
    width: auto;
    align-items: center;
}

.container__middle > i {
    color: var(--button-2);
}

.container__middle--top {
    position: sticky;
    top: 0;
}

/* Mobile add step */

.steps__input__mobile {
    position: sticky;
    bottom: 0;
    background-color: var(--menu);
    gap: 10px;
    padding: 10px;
}

.mobile__step__btns {
    display: flex;
    flex-direction: row;
    gap: 5px;
    max-width: 100%;
}

.mobile__step__btns > input {
    flex: 2;
    background-color: var(--background-3);
    border: 1px solid var(--button-1);
    padding: 5px;
    font-size: 1.4em;
    color: var(--link-1);
    outline: none;
    border-radius: 5px;
    min-width: 180px;
}

.mobile__step__btns > button {
    flex: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2.2em;
    padding: 5px;
}

.step__btn__save {
    background-color: var(--button-1);
    color: var(--menu);
}

.step__btn__close {
    background-color: var(--transparent);
    color: var(--button-1);
}

.spinnerStarSpace svg{
    animation: spin 10s infinite linear; /* Gira continuamente a cada 2 segundos */
}

.profile-marked {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profile-marked > img {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 30px;
    height: 30px;
    border-radius: 7px;
    border: 2px solid var(--main-2);
}

.profile-mark {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profile-mark > img {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 30px;
    height: 30px;
    border-radius: 7px;
}


@keyframes spin {
    from {
        transform: rotate(0deg); /* Início da rotação */
    }
    to {
        transform: rotate(360deg); /* Roda 360 graus */
    }
}


/* alert */

@media only screen and (max-width: 650px) {
    .container {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
    }

    .container__middle {
        width: 100%;
        padding: 10px 5px;
        margin-bottom: 50px;

    }

    .container__left--menu {
        top: 0px;
        margin: 0px;
        padding: 10px;
    }
    

    
    .container__left--menu > fieldset {
        display: flex;
        flex-direction: row;
        border: none;
        padding: 0;
    }

    .container__left--menu > fieldset > a > b {
        display: none;
    }

    .container__left--menu > fieldset svg {
        font-size: 18pt;
    }

    .container__right {
        z-index: 10000;
        flex: 0;
        position: fixed;
        width: 100%;
        bottom: 0;
    }
}

@media only screen and (max-width: 600px) {
    .flow {
        display: flex !important;
    }
}

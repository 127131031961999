.theme {
    display: flex;
    flex-direction: column;
    width: 60%;
    gap: 16px;
    padding: 16px 0px;
}

.theme__grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Ajusta automaticamente */
    gap: 16px; /* Espaçamento entre os elementos */
}
  
@media (max-width: 600px) {
    .theme {
        width: 100%;
    }
    
    .theme__grid {
        width: 100%;
        grid-template-columns: 1fr; /* Um único elemento por linha em telas menores */
    }
}
  
input.colors[type="color"] {
    -webkit-appearance: none; /* Remove estilo padrão em navegadores baseados no WebKit */
    -moz-appearance: none;    /* Remove estilo padrão em Firefox */
    appearance: none;         /* Remove estilo padrão geral */
    cursor: pointer;
    overflow: hidden;
    border: none;
    padding: 0;
    background-color: transparent;
    width: 40px;
    height: 40px;
    transition: all .2s ease;
}

input.colors[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  
input.colors[type="color"]::-webkit-color-swatch {
    border: 1px solid var(--background-3);
    border-radius: 8px; /* Aplica borda arredondada no botão */
}

input.colors[type="color"]:hover::-webkit-color-swatch {
    border: 1px solid var(--main-2);
    border-radius: 8px; /* Aplica borda arredondada no botão */
}

input.colors[type="color"]:focus::-webkit-color-swatch {
    border: 1px solid var(--main-2);
    border-radius: 8px; /* Aplica borda arredondada no botão */
}

.square__1,
.square__2,
.square__3 {
    height: fit-content;
}

.square__1 {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.square__dv {
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.square__dv > label {
    font-size: 1.1rem;
    color: var(--white-1);
}

.square__dv > select {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    background-color: var(--background-1);
    border: 1px solid var(--background-3);
    color: var(--white-1);
    padding: 5px 8px;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
}

.square__dv > select > option:hover {
    background-color: var(--main-2);
}

.save__theme {
    font-size: 1.2rem;
    padding: 10px 25px;
    background-color: var(--alert-success-2);
    color: var(--background-1);
}

.theme__grid__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}

.save__theme:hover {
    background-color: var(--alert-success-1);
}

@media only screen and (max-width: 650px) {
    .theme__grid__button {
        position: sticky;
        bottom: 65px;
        padding: 0px 10px;
        justify-content: end;
    }

    .theme__grid__button > .save__theme {
        display: flex;
        flex-direction: column;
        font-size: 2.5rem;
        border-radius: 100%;
        padding: 5px;
        border: 2px solid var(--alert-success-1);
    }
}
.profile {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: var(--white-1);
}

.profile__junt {
    display: flex;
    flex-direction: row;
}

.profile__photo {
    width: auto;
    height: auto;
    padding: 0px 10px;
    border-radius: 10px;
}

.profile__photo img {
    position: relative;
    width: 100%;
    max-width: 150px;
    height: 100%;
    object-fit: cover;
    max-height: 150px;
    aspect-ratio: 1 / 1;
    border-radius: 10px;
}

.profile__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 10px;
    justify-content: space-between;
}

.profile__info__title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.profile__info__title div {
    flex: 1;
    border: 1px solid var(--background-3);
    border-radius: 10px;
    padding: 3px 10px;
}

.profile__info__title .social-media {
    display: flex;
    flex-direction: row;
    border: none;
    padding: 0;
}

.profile__info__title .social-media > a {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    font-size: 1.7em;
    color: var(--button-2);
    transition: all .3s ease;
}

.profile__info__title .social-media > a:hover {
    color: var(--button-1);
}

.profile__info__name > h3{
    color: var(--main-2);
}

.profile__info__plus {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 12pt;
    justify-content: space-between;
}

.profile__info__plus a {
    color: var(--main-2);
    font-size: 1em;
}

.profile__info__plus > button {
    padding: 3px;
    font-size: 1.3em;
    background-color: var(--main-2);
    color: var(--background-1);
    font-weight: bold;
}

.profile__info__plus > button:hover {
    background-color: var(--alert-success-2);
}

.profile__info__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}   

.profile__info__button > button {
    display: flex;
    flex-direction: column;
    padding: 3px;
    font-size: 2em;
    background-color: var(--background-3);
    color: var(--white-1);
    font-weight: bold;
}

.profile__info__button > button:hover {
    background-color: var(--main-2);
    color: var(--bbackground-1);
}

.profile__image__edit {
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.profile__image__edit > label {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--button-2);
    border-radius: 10px;
}

.profile__image__edit > label:hover {
    cursor: pointer;
    background-color: var(--button-1);
}

.profile__image__edit > label > img {
    opacity: 0.8;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Faz com que a imagem preencha o espaço sem distorcer */
    object-position: center; /* Centraliza a imagem */
}

.save__photo {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.save__photo > button {
    flex: 1;
    font-size: 1.5em;
}

.save__photo__profile {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 0px;
    background-color: var(--alert-success-2);
}

.close__photo__profile {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 0px;
    background-color: var(--alert-fail-2);
}

.imageProfile__edit {
    display: none;
}

@media only screen and (max-width: 800px) {
    .profile {
        flex-direction: column;
        display: flex;
        height: auto;
    }

    .profile__junt {
        display: flex;
        flex-direction: column;
    }

    .profile__photo {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        align-items: center;
    }

    .profile__photo img {
        position: relative;
        width: 150px;
        height: 150px;
        aspect-ratio: 1 / 1;
        border-radius: 10px;
    }

    .profile__info__title {
        display: flex;
        flex-direction: column;
    }

    .profile__info {
        gap: 10px;
    }

    .profile__info__button {
        display: flex;
        flex-direction: row;
    }
    
}
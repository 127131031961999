.footer {
    display: flex;
    flex-direction: column;
}

.f-dv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--alert-success-2);
}

.f-container {
    display: flex;
    flex-direction: row;
    width: 80%;
}

.f-dv .social {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 10px 0px;
}

.f-dv h3 {
    font-size: 12pt;
    font-weight: 100;
}

.f-dv .social a {
    font-size: 20pt;
    display: flex;
    flex-direction: column;
    color: var(--alert-success-3);
}

.f-dv .social a:hover {
    color: var(--background-1);
}

/* MIDDLE */
.m-dv {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: var(--white-2);
}

.m-dv > .f-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.m-dv .f-container .f-grid:first-child {
    border: none;
    padding-left: 0px;
}

.m-dv > .f-container > .f-grid {
    flex: 1;
    padding: 30px 20px;
    border-left: 1px solid var(--white-1);
}

.m-dv .f-grid div {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.m-dv .f-grid h3 {
    font-size: 14pt;
}
.m-dv .f-grid h2 {
    font-size: 5em;
}

.m-dv .f-grid a {
    font-size: 13pt;
    transition: all .3s ease;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 0px;
    width: fit-content;
    color: var(--main-2);
}
.m-dv .f-grid a:hover {
    background-color: var(--background-1);
    padding: 3px 6px;
    transform: translateX(7px);
    cursor: pointer;
}

.l-dv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: var(--white-1);
    width: 100%;
}

.l-dv > .f-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 3px 0px;
}

@media only screen and (max-width: 660px) {
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .m-dv {
        display: flex;
        flex-direction: column;
    }

    .m-dv .f-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .m-dv .f-container .f-grid:first-child {
        border: none;
        padding: 30px 20px;
    }
    
    .m-dv > .f-container > .f-grid {
        flex: 1;
        padding: 30px 20px;
        border-top: 1px solid var(--white-1);
    }
}